@import '~react-vis/dist/style';
@import '../../node_modules/react-grid-layout/css/styles.css';
@import '../../node_modules/react-resizable/css/styles.css';

   @font-face {
    font-family: 'Azonix';

    src: local('Azonix'), url('../resources/Azonix.woff') format('woff');
    }
:root {
  --blue: #0b468c;
  --purple: #8c00a5;
  --background: #b7c1de3a;

  --dark-blue:#092047;
  --light-blue-opacity : #b7c1de3a;
    --light-blue : #b7c1de;
  --light-purple:  #8c00a556;

}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Quicksand' !important;
  scroll-margin: 3.9em;
}
body {
  background-color: var(--background) ;
}

html {
  scroll-behavior: smooth;
}

 

.loading {
  width: 100%;
  height: 450px;
  cursor: progress;
  animation: loading 1.5s infinite;
  background: 
  linear-gradient(0.25turn, transparent, #fff, transparent),
  linear-gradient(#eee, #eee),
  radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
  linear-gradient(#eee, #eee);
  background-color: #ddd;
  border-radius: 8px;
}

@keyframes loading {
  to {
    background-position: 1200px 0;
  }
}



@keyframes shake {
  0% { transform: rotate(0deg); }
  33% { transform: rotate(-0.5deg); }
  67% { transform: rotate(0deg); }
  100% { transform: rotate(0.5deg); }

}

