.card {
  padding: 2.5em;
  width: 600px;
  border: none;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  box-shadow: 5px 5px 7px 2px #6e6e6e, -5px -5px 7px 2px #6e6e6e;
}

.submitButton {
  
  background-color: var(--blue) ;
  color: #fff ;
  font-size: 20px ;
  letter-spacing: 1px ;
  text-transform: capitalize ;
  margin: 1em auto ;
  border: 0;
  border-radius: 2em;
  padding: 8px 64px;
  font-size: 1.05rem
}