a{
  color: inherit;
  text-decoration: none
}

  .drawer {
    width: 280px;
    z-index: 1;
    flex-shrink: 0
  }
  .drawerPaper {
    background-color: var(--dark-blue);
    width: 280px
  }
  .drawerContainer {

    height:100vh 
  }
  .items {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    width: 100%;
  }
  .items * {
    font-size: 1.2rem;
    color:rgba(235, 235, 235, 0.877)
  }
  .items   svg {
    font-size: 1.65rem ;
  }