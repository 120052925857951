.title {
  text-align: center;
  padding: 01em 1em 1em;
}

.subTitle {
  text-align: center;
  font-size: 1em;
  color: #2d333a;
  margin: 1em 1em 2em 1em ;
}
.form {
  display: flex;
}

.formControl {
  width: 100% ;
  padding : 1em 
}

.submitButton ,.submitButton:hover{

  font-size: 1.3rem;
  padding: 0.45em 5.5em;
  text-transform: capitalize;
  border-radius:8px ;
  background-color: var(--blue) ;
  color: #fff;
    margin : 1em 0 
}

.iconContainer {
  display: flex;
  justify-content: center;
}

.icon {
  width:  8em;
  height: 8em;
}

.card {
  padding: 3em;
  padding-top: 1.5em;
  width: 400px;
  background-color: #fff;
  border-radius: 1em;

position: absolute;
  
}