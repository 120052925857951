.title {

  width: 86px;
  


}

.toolbar {
  display: flex;
  padding: 10px 10px;
}

.username {
  color: #5f5f5f;
  font-size: 1em;
}



.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 10px;



}
.user * *  {
  width:  70px;
  height: 70px;

}


.searchwrapper {
  display: flex;
  gap:2px;
  align-items: center;
  height: 45px;
  flex : 1 1 auto ;

  max-width: 800px;
}



.user > div >div> img {
  
  
  width:100%;
  height: 100%;
}





.searchIcon {
  height: 100%;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius:   8px 2px 2px 8px ;
  color: #202020;
  

  
  
}

.searchIcon > * {
  font-size: 2rem;
  
}



.inputRoot {
     max-width: 800px;
  width:100%;
font-size:1.2rem;
}

.inputInput {
  position: relative;
  display: flex;

  border-bottom: 1px solid var(--light-blue);
  width: 100%;
  align-items: center;
  color: #000 ;

  padding: 8px 8px 8px 12px ;

}
.inputInput::placeholder { 
  color: var(--light-blue);
  opacity: 1 ; 
}





.menuIcon {
  font-size: 1.9rem
}


