.root {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.content {
  margin-top: 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
  

}
@media only screen and (max-width: 1380px)  {
  .content{
      margin-left: 0;
    width: 100%;
        padding: 0 3px;
  }
}



